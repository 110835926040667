// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Layout$DraftbitPersonaAvatarGenerator from "../components/Layout.bs.js";

function Page_404(Props) {
  return React.createElement(Layout$DraftbitPersonaAvatarGenerator.make, {
              children: null
            }, React.createElement("h1", undefined, "NOT FOUND"), React.createElement("p", undefined, "You just hit a route that doesn't exist... the sadness."));
}

var make = Page_404;

var $$default = Page_404;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
